import $, { extend } from 'jquery';
import DataTable from 'datatables.net';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';

import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';
import {ReactSummernote} from  'react-summernote';
import 'react-summernote/dist/react-summernote.css';

import {Select} from 'react-select-2';




import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCert,appGetUrlPath,RespBkMthdMapp,getSearchOutPut,TableDatePicker,FormatDate} from '../function_lib/common_lib';
import {StateIndia} from '../function_lib/city_india';
import {Comp_View_Document} from '../components/comp_view_document';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Library_Opt_Modules from '../user_pages/library_opt_modules';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
let stateList=[];
let appUrtPath;
window.Popper = Popper;
class Library_Acts extends React.Component{
  
forceUpdateHandler(){
  this.forceUpdate();
};

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
         
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          
      }
      stateList=StateIndia();
      this.state={ listLib:[]};
      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      appUrtPath=appGetUrlPath();
      //alert(stateList.length);
      }
      saveLibrary(){
        //alert($("#edit_id").val());
        if($("#edit_id").val().trim()===""){
          GetImgCert(clsObject,"#frmLibCreate","addLibraryList","okSuccLib");
        }else{
          //alert("i m in edit ");
          GetImgCert(clsObject,"#frmLibCreate","editLibraryList","okSuccLib");
        }
        
      }
      setDocumentView(docName){
           //alert(appUrtPath+"/library_dfile/"+docName);
//        document.getElementById("samp_comp_pa").src=appUrtPath+"library_dfile/"+docName;
let srcPath=appUrtPath+"library_dfile/"+docName;
//alert(docName);
if(docName.indexOf(".xlsx")!==-1 ||docName.indexOf(".xls")!==-1 || docName.indexOf(".docx")!==-1 ||docName.indexOf(".doc")!==-1){
  //alert("i m in click path");
  srcPath="https://view.officeapps.live.com/op/view.aspx?src="+srcPath;
}
alert(srcPath);
document.getElementById("samp_comp_pa").src=srcPath;
      }
      
      okSuccLib(data,msg){
        alert(data+" "+msg);
        if(data==="011"){
          $(".respSSL").html("Data Created Successfully");
          window.location.reload();
          clsObject.getLibraryByType();
        }else{
          $(".respSSL").html("Unable to Create Record..");
        }
      }

getLibraryByType(){
  var libType=[];
  libType.push({name:"curl_type",value:"getLibraryList"});
  libType.push({name:"res_func",value:"callBackLibraryList"});
  //libType.push({name:"local_token",value:userObject[0].app_user_id});
  //libType.push({name:"ref2_id",value:userObject[0].id});
  libType.push({name:"type_id",value:"10"});
  libType["res_func"]="callBackLibraryList";
  libType["curl_type"]="getLibraryList";
  CallMapMethod(this,libType);
}
callBackLibraryList(data,msg){
 //alert(data);
  if(data!=="0" && data!==""){
    let listLib=JSON.parse(data);
    
    this.setState({listLib:listLib},()=>{
      RespBkMthdMapp(clsObject, "_setbackparam", "rmLibraryRec");
      $('#tbData').DataTable({
        paging: true,
        searching: true,
        bLengthChange : false,
        dom: 'tp',
        aaSorting:[],
        
      });

      //$('#tbData').DataTable();
    
    });
    this.forceUpdateHandler();
  }
}
//Function for Delete
rmLibraryRec(data){
  if(window.confirm("Do you want to Delete ?")===true){
  var libType=[];
  libType.push({name:"curl_type",value:"deleteFromLibList"});
  libType.push({name:"res_func",value:"callBackDeleteList"});
  //libType.push({name:"local_token",value:userObject[0].app_user_id});
  //libType.push({name:"ref2_id",value:userObject[0].id});
  libType.push({name:"p_ref",value:data});
  libType.push({name:"type_id",value:"10"});
  libType["res_func"]="callBackDeleteList";
  libType["curl_type"]="deleteFromLibList";
  CallMapMethod(this,libType);
  }
}
callBackDeleteList(data,msg){
  if(data==="001"){
    alert("Deleted Successfully");
    clsObject.getLibraryByType();
  }
}

//Function for Edit
editLibraryRec(data){
  //alert("Unable to process Edit "+JSON.stringify(data));
  $("#edit_id").val(data["_id"]);
  $("#state_name").val(data["state_name"]);
  $("#title_name").val(data["title_name"]);
  $("#year").val(data["year"]);
  //$("textarea[name='description']").val(data["description"]);
  $("#description").summernote('code', data["description"]);
  let fmDate=FormatDate(data["enactment_date"]);
  //alert(fmDate);
  $("#enactment_date").val(fmDate);
  $("#extDate").html(fmDate);
  $("#ex_files").val(data["upload_path"].trim());

  clsObject.listExistingFiles(data["upload_path"]);
  /*
  if(data["upload_path"].trim()!==""){
    let strListFiles= clsObject.listExistingFiles(data["upload_path"]);
    $("#listFiles").html(strListFiles);
    RespBkMthdMapp(clsObject,"_removeBtn","rmFileProc","2");
   
  }
  */
 
  //$("textarea#description").val(data["description"]);
}
rmFileProc(rmName){
  //alert("have to remove "+rmName);
  let getExitList=$("#ex_files").val();
  clsObject.listExistingFiles(getExitList,rmName);

}
listExistingFiles(othFiles,rmName=""){
  let arrFiles=othFiles.split(',');
  let strSet="";
  let assignFiles="";
  for(let i=0;i<arrFiles.length;i++){
    if(rmName!==arrFiles[i]){
      if(assignFiles!==""){
        assignFiles+=",";
      }
      assignFiles+=arrFiles[i];
      strSet += "<div id=\"ref_div_"+arrFiles[i]+"\" class=\"col-lg-4 col-md-4 col-sm-4 col-6 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close _removeBtn"+""+"\" param1=\""+arrFiles[i]+"\" aria-hidden=\"true\"></i>"+
      arrFiles[i] +
        "</div>";
    }
    
  }
  
  $("#listFiles").html(strSet);
  $("#ex_files").val(assignFiles);
  RespBkMthdMapp(clsObject,"_removeBtn","rmFileProc","2");
  //return ;
}
//End of Edit Funcitons

 createMarkup(data) {
  return {__html: data};
}      

componentDidMount(){
    clsObject=this;
    this.getLibraryByType();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);
$(".cleditor").summernote();
//$(".select2").select2();
//$('.selectpicker').selectpicker();
//$(".dropdown-toggle").dropdown();
RespBkMthdMapp(clsObject,"_setSearchKeyUp","getSearch");

$('#divEmployee').on('hidden.bs.modal', function () {
  // do something…
  /*
  $("#frmLibCreate").find("input[type=text]").val("");
  $("#frmCreateLoan").find("select option[value=\"0\"]").attr("selected",true);
  $("#frmLibCreate").find(".reset_val").val("");
  $("#edit_id").val("");
  $("#ex_files").val("");
  */
 /*$("#formID input[type=text]").val("");
$("#formID select").prop("selectedIndex", 0);
$("#formID input[type=radio]").prop("checked", false);
$("#formID input[type=checkbox]").prop("checked", false);
*/
  $("#frmLibCreate")[0].reset(); //Hidden Fileds not reseted
  $("#edit_id").val("");
  $("#ex_files").val("");
  $("#listFiles").html("");
  $(".respSSL").html("");
});

}
getSearch(data){
  if(data==="0"){
    data="";
  }
  // let oTable = $('#tbData').DataTable();   //pay attention to capital D, which is mandatory to retrieve "api" datatables' object, as @Lionel said
  //   oTable.search(data).draw() ;
  getSearchOutPut("desk_thumb","child_desk_thumb",data,true,"tbData");
 }
/* Check custom Search */
 

      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-2"> 
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          { userObject[0].id==="VKeBS5Qp44FXKLwPLhb+ZA=="? <div className="card-footer p-0">
            <ul className="nav flex-column  table-hover">
              <li className="nav-item"> <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" data-toggle="modal" data-target="#divEmployee">Add New Acts</span></h6> </a> </li>
            </ul>
          </div>:''}
         
          
          {/* <!-- /.widget-user -->  */}
        </div>
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
         <Library_Opt_Modules/>
          
          {/* <!-- /.widget-user -->  */}
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-12">
      <div class="card" id="desk_thumb">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
           <div className="row">
             <div className="col-lg-3 col-md-3 col-sm-4">
             <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Acts </b></h3>
             </div>
   
  
  
    <div className="col-lg-3 col-md-3 col-sm-4">
    <select onChange={(event)=>this.getSearch(event.target.value)} className="form-control form-control-sm" id="ser_state" name="ser_state">
                <option value="0">-All State-</option>
                {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option value={item.name}>{item.name}</option>)
                                                  }):''}
              </select>
    </div>
    <div className="col-lg-5 col-md-5 col-sm-5">
    <div className="input-group">
                    <input autoComplete="off" type="text" id="searchTxt" name="searchTxt" className="form-control form-control-sm _setSearchKeyUp"   placeholder="Search" />
                    <div className="input-group-prepend">
                      <span className="input-group-text form-control-sm currency"><i className="fa fa-search" aria-hidden="true"></i></span>
                    </div>
                  </div>
    </div>
  </div>
              </div>
               
<table id="tbData">
  <thead>
    <tr><td></td></tr>
  </thead>
<tbody>
 {this.state.listLib.length>0?this.state.listLib.map((item,index)=>{
  let arrFiles=[];
  if(item.upload_path.trim()!==""){
    arrFiles=item.upload_path.split(",");
   
  }
                        return (<tr><td><div class="callout callout-danger child_desk_thumb">
                        <h5>{item.title_name}
                        {userObject[0].id==="VKeBS5Qp44FXKLwPLhb+ZA=="?<div><a href="javascript:void(0)" onClick={()=>this.rmLibraryRec(item._id)} class="float-right btn-tool"><i class="fa fa-times"></i></a>
                        <a href="javascript:void(0)" data-toggle="modal" data-target="#divEmployee" onClick={()=>this.editLibraryRec(item)} class="float-right btn-tool"><i class="fa fa-pencil"></i></a></div>
                        :''}
                        </h5>
                        <p className='pt-10px'>
                        <a href="#" class="link-black text-sm mr-2"><i class="fa fa-share mr-1"></i> {item.state_name}</a>
                        <a href="#" class="link-black text-sm"><i class="fa fa-calendar-times-o mr-1"></i><b>Year : </b>{item.year}</a>
                        {item.upload_path!==""?<span class="float-right">View Uploads
                        {arrFiles.map((fItem,fIndex)=>{
                           let viewLists=<i class="fa fa-file-pdf-o ml-2 mr-2 fa-2x"></i>;
                           if(fItem.indexOf(".pdf")!==-1){
                             viewLists=<i class="fa fa-file-pdf-o ml-2 mr-2 fa-2x"></i>;
                           }
                           else if(fItem.indexOf(".xlsx")!==-1 || fItem.indexOf(".xls")!==-1){
                              viewLists=<i class="fa fa-file-excel-o ml-2 mr-2 fa-2x"></i>;
                           }else if(fItem.indexOf(".docx")!==-1 || fItem.indexOf(".doc")!==-1){
                             viewLists=<i class="fa fa-file-word-o ml-2 mr-2 fa-2x"></i>;
                           }else{
                             viewLists=<i class="fa fa-file-image-o ml-2 mr-2 fa-2x" aria-hidden="true"></i>;
                           }
 
                        return(
                          <a href="javascript:void(0)" onClick={()=>this.setDocumentView(fItem)} data-toggle="modal" data-target="#divEmpViewLibDoc" class="link-black text-sm">
                          {viewLists}

                        </a>)
 })}
                          
                        </span>:''}
                      </p>
                        <p><div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </p>
                      </div></td></tr>)
                    }):''}
             </tbody></table>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<Comp_View_Document/>
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Acts Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmLibCreate" name="frmLibCreate" enctype="multipart/form-data" method="POST" action="#">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="10" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <input type="hidden" name="edit_id" id="edit_id" value=""/>
                            <input type="hidden" name="ex_files" id="ex_files" value=""/>

                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">State *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="state_name" name="state_name" className="form-control">
                                                  <option value="0">-Select-</option>
                                                  {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option value={item.name}>{item.name}</option>)
                                                  }):''}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Act Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="title_name" name="title_name" className="form-control" placeholder="Act Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Year *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="year" name="year" className="form-control" placeholder="year" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                     <div id="listFiles" className='row'>

                                     </div>
                                    <div className="form-group">
                                      {/* <input type='file' id="fl_name" name="fl_name" /> */}
<label for="upload_path">Upload Proof(.jpg,png,jpeg,pdf,doc,docx,zip allowed)</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" multiple className="custom-file-input1" name="upload_path" id="upload_path" />
    {/* <label className="custom-file-label" for="upload_path">Choose file</label> */}
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Remarks if Any</label>
                                            <div className="input-group input-group-sm">
                                              {/* <textarea id="description" name="description"></textarea> */}
                                                 <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="description" name="description"></textarea> 
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-6 col-md-6">
                                    <div className='form-group'>
                                    <label for="exampleInputEmail1">Date of Enactment *</label>
                                    <div id="extDate"></div>
                                            <div className="input-group input-group-sm">
                                              {/* <TableDatePicker refName="enactment_date"/> */}
                                                 <input type="text" id="enactment_date" name="enactment_date" className="form-control" placeholder="Enactment Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask /> 
                                            </div>
                                    </div>

                                   </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" onClick={()=>this.saveLibrary()} className="btn btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Library_Acts;